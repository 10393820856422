<template>
  <div class="opage">
    <HomePageTop />

    <div class="forgetPsd">
      <h1 class="forgetPsdTitle">
        <span>{{ $fanyi('忘记密码') }}</span>
        <hr />
      </h1>
      <!--  <div class="checkMethods" v-if="step == 2"> -->
      <div class="checkMethods" v-if="step == 2">
        <div class="checkMethodsCon">
          <h1>{{ $fanyi('请选择验证方式') }}：</h1>
          <div class="chioseBox">
            <!--手机号码 认证-->
            <div class="checkMethodsOpt" @click="
              approveType = 'mobile'
            next()
              ">
              <div class="imgBox">
                <img :src="require('@/assets/newImg/mobile_1.svg')" alt="" />
                <img :src="require('@/assets/newImg/phone.svg')" alt="" />
              </div>

              <button>
                {{ $fanyi('手机号码验证') }}
              </button>
            </div>
            <div class="checkMethodsOpt" @click="
              approveType = 'email'
            next()">
              <div class="imgBox">
                <img :src="require('@/assets/newImg/email_1.svg')" alt="" />
                <img :src="require('@/assets/newImg/email_2.svg')" alt="" />
              </div>

              <button>
                {{ $fanyi('电子邮箱验证') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="changePasswordBox" v-if="step >= 3">
        <p class="userInfo" v-if="step == 3">
          <span>
            <span v-if="approveType == 'mobile'">{{
              $fanyi('手机号码验证')
            }}</span>
            <span v-if="approveType == 'email'">{{
              $fanyi('电子邮箱认证')
            }}</span>
          </span>
          <!-- <button
            @click="
              step = 2
                                                                                                                                                                                                                                                                                                                                                                                      onumber = ''
            regCode = ''
                                                                                                                                                                                                                                                                                                                                                                                    ">
            {{ $fanyi('其他认证方式') }}
          </button> -->
        </p>
        <!-- <p class="successMessage" v-if="step == 5">
          {{ $fanyi('登录密码修改成功') }}
        </p> -->
        <nav class="step">
          <div class="stepOpt" :class="{ old: step > 3, active: step == 3 }">
            <label>
              <span v-if="step < 4">1</span>
              <img v-else :src="require('@/assets/login/gou.png')" alt="" />
            </label>
            <span class="text">{{ $fanyi('身份验证') }}</span>
          </div>
          <hr />
          <div class="stepOpt" :class="{ old: step > 4, active: step == 4 }">
            <label>
              <span v-if="step < 5">2</span>
              <img v-else :src="require('@/assets/login/gou.png')" alt="" />
            </label>
            <span class="text">{{ $fanyi('登录密码修改') }}</span>
          </div>
          <hr />
          <div class="stepOpt" :class="{ old: step > 5, active: step == 5 }">
            <label>
              <span v-if="step < 6">3</span>
              <img v-else :src="require('@/assets/login/gou.png')" alt="" />
            </label>
            <span class="text">{{ $fanyi('更改成功') }}</span>
          </div>
        </nav>
        <div class="identityVerification" v-if="step == 3">
          <div class="regInputBox">
            <label>
              {{
                approveType == 'mobile'
                  ? $fanyi('请输入您的手机号')
                  : $fanyi('请输入邮箱')
              }}：</label>
            <div class="mobile" v-if="approveType == 'mobile'">
              <span class="quhao"> <el-select v-model="country">
                  <el-option v-for="item in Areacodelist" :key="item.sx" :label="item.value" :value="item.value">
                    {{ $store.state.languagetype == "English" ? item.sx + ' ' + item.value : item.key + ' ' +
                      item.value }}
                  </el-option>
                </el-select></span>
              <input type="text" v-model="onumber" :placeholder="approveType == 'mobile'
                ? $fanyi('请输入正确的手机号码')
                : $fanyi('请输入完整电子邮箱')
                " />
            </div>

            <div class="emali" v-else>
              <input type="text" v-model="onumber" :placeholder="approveType == 'mobile'
                ? $fanyi('请输入正确的手机号码')
                : $fanyi('请输入完整电子邮箱')
                " />
            </div>

            <span class="prompt" v-if="approveType == 'mobile'">{{
              $fanyi('*如果手机号码输入错误，将无法发送验证码')
            }}</span>
            <span class="prompt" v-else-if="approveType == 'email'">{{
              $fanyi('*如果邮箱输入错误，将无法发送验证码')
            }}</span>
          </div>
          <div class="regInputBox">
            <label>{{ $fanyi('验证码') }}：</label>
            <div class="regCodeBox">
              <input type="text" v-model="regCode" :placeholder="$fanyi('请输入验证码')" />
              <button v-if="showBtn" class="getCodeBtn" @click="sendVerificationCode">
                {{ $fanyi('验证码获取') }}
              </button>
              <button v-else class="getCodeBtn countdown">
                {{ Countdown }} S
              </button>
            </div>
          </div>
          <button class="nextBtn" @click="next()">
            {{ $fanyi('下一步') }}
          </button>
        </div>
        <div class="newPassWordBox" v-if="step == 4">
          <el-form ref="formRef" :rules="formRules" :model="newPassword">
            <el-form-item :label="$fanyi('新密码') + '：'" prop="one" class="regerror">
              <el-input :class="{ successCss: newPasswordTrue.one }" v-model="newPassword.one"
                :placeholder="$fanyi('请输入新的密码')" @input="onePasswordChannelInputLimit"
                @focus="validateField('one', 'formRef')" @blur="validateField('one', 'formRef')"
                show-password></el-input>
            </el-form-item>
            <el-form-item :label="$fanyi('再次确认密码') + '：'" prop="two">
              <el-input :class="{ successCss: newPasswordTrue.two }" v-model="newPassword.two"
                :placeholder="$fanyi('请再次输入密码')" @input="twoPasswordChannelInputLimit"
                @focus="validateField('two', 'formRef')" @blur="validateField('two', 'formRef')"
                show-password></el-input>
            </el-form-item>
          </el-form>

          <button class="nextBtn" @click="next()">
            {{ $fanyi('下一步') }}
          </button>
        </div>
        <div class="ChangeSuccess" v-if="step == 5">
          <div class="ChangeSuccessOpt">
            <p class="messageBox">
              <img src="../../assets/newImg/omplete.png" alt="" />
              <span>{{ $fanyi('登录密码更改成功') }}</span><button class="nextBtn" @click="$fun.toPage('/')">
                {{ $fanyi('返回主页') }}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomePageTop from '../../components/head/HomePageTop.vue'
import Areacode from '../../utlis/mobilephoneareacode.js'
export default {

  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$fanyi('请再次输入密码')))
      } else if (value !== this.newPassword.one) {
        callback(new Error(this.$fanyi('密码输入不一致')))
      } else {
        callback()
      }
    }
    return {
      step: 2,
      login_name: '', //用户名
      approveType: 'email', //用户验证身份的方式
      showBtn: true, //发送验证码按钮控制
      Countdown: 60, //秒数
      regCode: '', //验证码
      regNext: false,
      country: '+001', //手机号区号
      onumber: '', //绑定的邮箱或手机号
      tiShi: '',
      Areacodelist: Areacode,// 区号列表
      newPassword: {
        one: '',
        two: '',
      },
      newPasswordTrue: {
        one: false,
        two: false,
      },
      formRules: {
        one: [
          {
            required: true,
            pattern: /^.{6,16}$/,
            message: this.$fanyi('请输入6-请输入6-16位包含大小写字母和数字的密码'),
          },
        ],
        two: [
          {
            required: true,
            validator: validatePass2,
          },
        ],
      },
    }
  },
  components: { HomePageTop },
  computed: {},
  created() { },
  methods: {
    // 手动检验数据函数
    validateField(type, formRef) {
      if (this.newPassword[type])
        this.newPassword[type] = this.newPassword[type].trim()
      this.$refs[formRef].validateField(type, (v) => {

        if (v == '') {
          this.newPasswordTrue[type] = true
        } else {
          this.newPasswordTrue[type] = false
        }
        this.$forceUpdate()
      })
    },
    // 设置新密码
    forgetPasswordReset() {
      let datas = {
        password: this.newPassword.one,
        login_name:
          this.approveType == 'mobile' ? this.onumber.trim() : this.onumber,
        verification: {
          number: this.onumber,
          code: this.regCode,
        },
      }
      this.$api.forgetPasswordReset(datas).then((res) => {
        if (res.success == true) {
          this.step++
        } else {
          this.$message.error('未查找到该账号，请核对后再尝试或联系客服处理');
          this.regNext = false
        }
      })
    },
    // 验证验证码
    checkVerificationCode(fn) {
      this.$api
        .checkVerificationCode({
          occasion: 'forget',
          number: this.onumber,
          code: this.regCode,
        })
        .then((res) => {
          if (res.success == true) {
            fn()
          } else {
            this.$message.warning(this.$fanyi('验证码错误'))
          }
        })
    },
    // 发送验证码
    sendVerificationCode() {

      if (!this.onumber) {

        return this.$message(this.$fanyi('缺少必填选项'))

      }
      // // 进行邮箱格式验证
      // var emailRegExp = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      // if (this.approveType == 'email' && !emailRegExp.test(this.onumber)) {
      //   return this.$message(this.$fanyi('请输入正确的邮箱地址'))
      // }
      //  手机号格式验证

      // let mobileReExp = /^(\w){10}$/

      let datas = {
        occasion: 'forget',
        tool: this.approveType,
        number: this.onumber,
      }
      if (this.approveType == 'mobile') {
        datas.area_code = this.country

        // 选择区号后不做校验
        // if (mobileReExp.test(this.onumber)) {

        // } else {

        //   return this.$message(this.$fanyi('请输入正确的手机号'))
        // }

      }
      this.showBtn = false
      this.Countdown = 60
      this.$api.sendVerificationCode(datas).then((res) => {
        this.showBtn = true
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg))
        this.showBtn = false
        var timer = setInterval(() => {
          this.Countdown--
          if (this.Countdown <= 0) {
            this.showBtn = true
            this.Countdown = 60
            clearInterval(timer)
          }
        }, 1000)
      })
    },
    onePasswordChannelInputLimit(e) {
      // this.data.expressNumber = e.replace(/[^A-Za-z0-9]/g, '');
      this.newPassword.one = e.replace(/[^A-Za-z0-9~!@#$%^+-.]/g, '')
    },
    twoPasswordChannelInputLimit(e) {
      // this.data.expressNumber = e.replace(/[^A-Za-z0-9]/g, '');
      this.newPassword.two = e.replace(/[^A-Za-z0-9~!@#$%^+-.]/g, '')
    },
    next() {

      switch (this.step) {
        case 2:
          this.step++
          break
        case 3:
          if (!this.onumber || !this.regCode) {
            return this.$message(this.$fanyi('缺少必填选项'))
          }
          // var emailRegExp = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
          // if (this.approveType == 'email' && !emailRegExp.test(this.onumber)) {
          //   return this.$message(this.$fanyi('请输入正确的邮箱地址'))
          // }

          this.checkVerificationCode(() => {
            this.step++
          })
          break
        case 4:
          this.$refs.formRef.validate((valid) => {
            if (valid) {
              this.forgetPasswordReset()
            }
          })
          break
      }
    },
  },
}
</script>
<style lang="scss" scoped="scoped">
@import '../../css/mixin.scss';

.opage {
  background-color: #f6f6f6;
}

.mobile {
  display: flex;

  .quhao {
    width: 98px;
    height: 48px;
    background: #ffffff;
    // border: 1px solid #dedede;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;


    /deep/ .el-select {
      width: 98px;
      height: 48px;

      .el-input__inner {
        height: 48px;

        &:focus {

          border-color: #e2e2e2;
        }

        // &:blur {}
      }
    }
  }

}

.forgetPsd {
  width: 1400px;
  min-height: 678px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  margin: 0 auto;

  border-radius: 6px;
  padding: 40px 40px 40px;



  .forgetPsdTitle {
    span {
      font-size: 40px;
      font-weight: 500;
      line-height: 60px;
      margin-bottom: 10px;
    }

    hr {
      width: 48px;
      height: 4px;
      background-color: #ff730b;
    }
  }

  // 输入账号
  .inputUserNameBox {
    width: $pageWidth;
    margin: 0 auto;
    height: 442px;
    background: #ffffff;
    border: 1px solid #e1e1e1;

    .inputUserNameBoxCon {
      padding-top: 80px;
      width: 355px;
      margin-left: 523px;

      .title {
        margin-bottom: 40px;
        font-size: 18px;
        color: #000000;
        line-height: 24px;
      }

      .inputBox {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        label {
          // width: 240px;
          font-size: 14px;

          color: #000000;
          line-height: 19px;
        }

        input {
          width: 170px;
          height: 26px;
          background: #f6f6f6;
          border-radius: 5px;
          border: 1px solid #d7d7d7;
          margin-right: 10px;
          padding-left: 10px;
        }

        img {
          width: 20px;
          height: 20px;
        }

        .tishi {
          color: red;
          font-size: 12px;
        }
      }

      .buttonBox {
        text-align: center;

        button {
          width: 96px;
          height: 40px;
          background: $homePageSubjectColor;
          border-radius: 4px;
          color: #ffffff;
          font-size: 16px;
        }
      }
    }
  }

  // 确认认证方式
  .checkMethods {
    .checkMethodsCon {
      min-width: none;
      width: 450px;
      margin: 0 auto;
      padding-top: 80px;

      h1 {
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        margin-bottom: 40px;
      }

      // 所有验证选项
      .chioseBox {
        display: flex;
        justify-content: center;
        align-items: center;

        // 验证单个选项
        .checkMethodsOpt {
          width: 240px !important;
          height: 240px;
          background: #ffffff;
          border: 2px solid #e2e2e2;
          display: flex;
          justify-content: center;
          border-radius: 6px;
          align-items: center;
          flex-direction: column;
          cursor: pointer;
          transition: 0.3s;

          &:first-child {
            margin-right: 80px;
          }

          // 验证选项图片盒子
          .imgBox {
            width: 64px;
            height: 64px;
            position: relative;
            margin-bottom: 21px;

            img {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);

              &:last-child {
                transition: 0.3s;
                opacity: 0;
              }
            }
          }

          button {
            font-size: 16px;
            line-height: 24px;
          }
        }

        // 验证单个选项鼠标滑过
        .checkMethodsOpt:hover {
          border: 2px solid #ff730b;

          .imgBox {
            img {
              &:last-child {
                transition: 0.3s;
                opacity: 1;
              }
            }
          }

          button {
            color: #ff730b;
          }
        }
      }
    }
  }

  // 修改密码
  .changePasswordBox {
    padding-top: 40px;

    .userInfo {
      width: 762px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto 40px;

      span {
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
      }

      button {
        font-size: 16px;
        color: #608bff;
        line-height: 24px;
        background-color: transparent;
        border: none;
        outline: none;
      }
    }

    .successMessage {
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      margin-bottom: 40px;
      width: 762px;
      margin: 0 auto;
    }

    .step {
      margin: 40px auto 40px;
      width: 762px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-family: PingFang-SC-Regular, PingFang-SC;

      hr {
        flex: 0 0 112px;
        height: 1px;

        background: #e1e1e1;
        border: none;
        margin: 0 20px;
      }

      .stepOpt {
        &.old {
          label {
            color: #ffffff;
            background: #608bff;
            outline: solid 3px rgba($color: #608bff, $alpha: 0.3);
            flex: 0 0 18px;
            height: 18px;
            line-height: 18px;
          }

          span {

            color: #222222;
          }
        }

        .text {
          display: flex;
          justify-content: flex-start;
          min-width: 100px;
        }

        &.active {
          label {
            background: #ff730b;
            outline: solid 3px rgba($color: #ff730b, $alpha: 0.3);
            flex: 0 0 18px;
            height: 18px;
            line-height: 18px;
            color: white;
          }

        }

        display: flex;
        align-items: center;
        justify-content: center;

        label {
          text-align: center;
          line-height: 24px;
          flex: 0 0 24px;
          height: 24px;
          background: #ffd5b5;
          color: #222222;
          font-size: 14px;
          border-radius: 50%;
          margin-right: 12px;
        }

        span {
          font-size: 16px;
          line-height: 18px;
          max-width: 200px;
          text-align: center;
        }

        img {
          width: 10px;
        }
      }
    }

    >div {
      width: 715px;
      margin: 0 auto;
    }

    // 身份验证
    .identityVerification {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .regInputBox {
        width: 480px;
        margin: 0 auto;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }

        display: flex;
        flex-direction: column;

        label {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 10px;
        }

        input {
          width: 100%;
          font-size: 14px;
          height: 48px;
          border-radius: 6px;
          background: #ffffff;
          border: 1px solid #e2e2e2;
          margin-bottom: 10px;
          padding: 0 15px;
        }

        .prompt {
          font-size: 14px;
          color: #e3372f;
          line-height: 20px;
          text-align: right;
        }

        .regCodeBox {
          position: relative;

          .getCodeBtn {
            position: absolute;
            right: 10px;
            top: 14px;
            font-size: 14px;
            color: #ff730b;
            line-height: 20px;

            &.countdown {
              color: #999999;
            }
          }
        }
      }
    }

    // 输入新密码
    .newPassWordBox {
      margin-top: 83px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .el-form-item,
      /deep/.el-form-item {
        width: 480px;
        display: flex;

        // justify-content: center;
        // align-items: center;
        flex-direction: column;

        .el-form-item__label {
          text-align: left;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 10px;

          &:before {
            content: '';
          }
        }

        .el-input {
          width: 100%;
          height: 48px;
          border-radius: 6px;
          border: 1px solid #ff730b;
        }

        .successCss {
          background: #fff2e8;
        }

        .el-input__inner {
          width: 100%;
          height: 100%;
          // background: rgba(255, 115, 11, 0.1);
          border: none;
          background-color: rgba(255, 255, 255, 0.1);
        }

        .el-form-item__error {
          top: 92%;
          font-size: 12px;
          line-height: 1;
        }
      }

      /deep/.el-input {
        width: 240px;
        height: 26px;
      }

      /deep/.el-input__inner {
        width: 240px;
        height: 26px;
        background: #f6f6f6;
        border-radius: 5px;
        border: 1px solid #d7d7d7;
      }
    }

    // 修改成功
    .ChangeSuccess {
      .ChangeSuccessOpt {
        .messageBox {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-top: 80px;
          margin-bottom: 40px;

          img {
            width: 40px;
            height: 40px;
            margin-bottom: 20px;
          }

          span {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 40px;
            color: #000000;
            line-height: 19px;
          }
        }
      }
    }
  }
}

// 星号
.import {
  font-size: 14px;

  color: #ff0000;
  line-height: 20px;
}

//第三部开始的下一步按钮
.nextBtn {
  height: 48px;
  background: #ff730b;
  line-height: 20px;
  letter-spacing: 1px;
  color: #ffffff;
  font-size: 16px;
  padding: 14px 35px;
  border-radius: 4px;
}

.regerror {
  /deep/.el-form-item__error {
    top: 80%;
  }
}
</style>
